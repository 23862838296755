.page-news__wrapper {
  @include container;
}

.page-news__filter {
  margin-bottom: 25px;
}

.page-news__list {
  @include list-reset;

  @media (min-width: $width-tablet-m) {
    display: flex;
    flex-wrap: wrap;
  }
}

.page-news__item {
  margin-bottom: 60px;

  @media (min-width: $width-tablet-s) {
    padding-right: 60px;
  }

  @media (min-width: $width-tablet-m) {
    box-sizing: border-box;
    margin-bottom: 70px;
    padding-right: 90px;
    width: 50%;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 50px;
    margin-right: 30px;
    padding-right: 0;
    width: calc((100% - 90px) / 4);
  }

  &:nth-child(4n) {
    @media (min-width: $width-laptop) {
      margin-right: 0;
    }
  }
}

.page-news__pagination {
  margin-bottom: 50px;
}
