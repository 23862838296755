.select {
  position: relative;

  &::after {
    display: none;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f107";
  }

  svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.select__src {
  @include input;

  min-height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
}
