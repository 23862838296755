.services {
  background-color: $color-bg-stripe;

  &--four {
    .services__item {
      min-height: 180px;

      @media (min-width: $width-laptop) {
        margin-bottom: 0;
        margin-right: 30px;
        width: calc((100% - 90px) / 4);
      }

      &:nth-child(3n) {
        margin-right: 30px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

.services__wrapper {
  @media (min-width: $width-laptop) {
    @include container;
    padding-top: 70px;
  }
}

.services__list {
  @include list-reset;

  @media (min-width: $width-laptop) {
    display: flex;
    flex-wrap: wrap;
  }
}

.services__item {
  position: relative;

  box-sizing: border-box;
  margin-bottom: 5px;
  padding: 30px 20px;
  min-height: 265px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: $width-laptop) {
    margin-bottom: 0;
    margin-right: 30px;
    width: calc((100% - 60px) / 3);
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;

    width: 4px;

    background-color: $color-prime;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.4);
  }

  * {
    position: relative;
    z-index: 10;
  }
}

.services__title {
  margin: 0;
  margin-bottom: 20px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  color: #ffffff;
  text-transform: uppercase;
}

.services__link {
  @include label-link;

  position: absolute;
  left: 0;
  bottom: 0;
}
