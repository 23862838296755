.calendar {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  padding: 35px 0;

  @media (min-width: $width-laptop) {
    margin-left: 0;
    margin-right: 0;
    padding: 30px 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.4);
  }

  * {
    position: relative;
    z-index: 10;
  }
}

.calendar__title {
  margin: 0;
  padding: 20px 15px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  color: #ffffff;
  text-transform: uppercase;

  background-color: $color-prime;

  @media (min-width: $width-laptop) {
    padding-left: 80px;
    padding-right: 80px;
  }

  &::after {
    @media (min-width: $width-map-icons-to-column) {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 70px;

      border-radius: 50%;
      background-color: $color-prime;
      transform: translate(50%);
    }
  }
}
