.search {
  position: relative;
  width: 60px;
  height: 60px;
}

.search__form {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;

  overflow: hidden;

  background-color: $color-prime;
  border-radius: 30px;

  &::after {
    content: "\f002";
    display: none;
    font-family: "Font Awesome 5 Regular";
    font-weight: 400;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 17px;
    z-index: -1;

    color: #ffffff;
    font-size: 25px;

    transform: translateY(-50%);
  }
}

.search__input {
  @include input-reset;

  box-sizing: border-box;
  display: block;
  padding: 10px 30px;
  height: 60px;
  width: 60px;

  color: #ffffff;
  font-family: "Avanti", "Arial", sans-serif;

  cursor: pointer;

  will-change: width;
  transition:
    width 0.4s,
    color 0.2s,
    background-color 0.2s,
    border-color 0.2s;

  &::placeholder {
    color: #ffffff;
    opacity: 0;
    transition: opacity 0.4s;
  }

  &:focus {
    width: 290px;
    outline: none;

    &::placeholder {
      opacity: 1;
    }
  }
}
