.personal__wrapper {
  @include container;

  @media (min-width: $width-tablet-m) {
    display: flex;
  }
}

.personal__image {
  margin: 0;
  width: 60px;
  height: 60px;

  @media (min-width: $width-tablet-s) {
    margin-right: 30px;
  }
}

.personal__name {
  @include input;

  padding-top: 5px;
  padding-bottom: 5px;

  &:disabled {
    padding-left: 5px;
    padding-right: 5px;

    font-family: "AvantGardeCTT", "Arial", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;
    text-transform: uppercase;
  }
}

.personal__input {
  @include input;

  padding-top: 5px;
  padding-bottom: 5px;
}

.personal__table {
  width: 100%;
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 400;
}

.personal__controls {
  @media (min-width: $width-tablet-m) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &::before {
    @media (min-width: $width-tablet-m) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      width: 1px;
      background-color: #000000;
    }
  }
}

.personal__btn,
.personal__submit {
  margin-right: 30px;
  cursor: pointer;

  @media (max-width: $width-tablet-m - 1) {
    @include btn--black;
  }

  @media (min-width: $width-tablet-m) {
    @include label-link--black;
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.personal__user {
  margin-bottom: 10px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 20px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 0;
  }
}

.personal__data {
  @media (min-width: $width-tablet-s) {
    display: flex;
    flex-wrap: wrap;
  }
}

.personal__text {
  @media (min-width: $width-tablet-s) {
    flex-grow: 1;
  }

  @media (min-width: $width-tablet-m) {
    width: 350px;
  }
}
