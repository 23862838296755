.leadership__title {
  margin: 0;
  margin-bottom: 25px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.leadership__list {
  @include list-reset;
  margin-bottom: 35px;
}

.leadership__name {
  margin: 0;
  font-family: "Avanti", "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.2;
}

.leadership__photo {
  margin: 0;
  margin-bottom: 10px;
}

.leadership__scroll {
  margin: 13px 0;
  height: 4px;
  background-color: $color-prime;
}

.leadership__drag {
  position: relative;
  top: -13px;

  width: 30px;
  height: 30px;

  background-color: $color-prime;
  border-radius: 50%;
  cursor: pointer;
}
