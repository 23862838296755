.user-area {
  margin: 0;
  color: $color-prime;
}

.user-area__link {
  @include link-reset;

  display: none;
  margin-right: 15px;

  font-family: "Avanti", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  text-align: right;

  border-bottom: 1px dashed currentColor;

  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }

  &:active {
    opacity: 0.7;
  }

  @media (min-width: $width-tablet-s) {
    display: inline-block;
  }

  &:last-child {
    margin-right: 0;
  }
}
