.page-info {
  p {
    margin: 0;
    margin-bottom: 15px;

    font-family: "Proxima Nova", "Arial", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.025em;
  }

  a {
    @include link-reset;

    color: $color-prime;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      outline: none;
    }

    &:active {
      opacity: 0.7;
    }
  }
}

.page-info__wrapper {
  @include container;
}
