.filter {
  &--capsule {
    .filter__list {
      flex-wrap: nowrap;

      @media (min-width: $width-laptop) {
        flex-wrap: wrap;
      }
    }

    .filter__item {
      margin-bottom: 0;

      @media (min-width: $width-laptop) {
        width: auto;
        height: auto;
      }
    }

    .filter__link {
      @include capsule-btn--light;

      justify-content: center;

      @media (min-width: $width-laptop) {
        min-width: 105px;
      }

      &--active {
        @include capsule-btn--dark;
      }
    }
  }
}

.filter__list {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
}

.filter__item {
  margin-bottom: 15px;
  margin-right: 15px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 25px;
  }

  @media (min-width: $width-tablet-m) {
    margin-right: 20px;
  }
}

.filter__link {
  @include btn--transparent;
  display: block;
}
