.logo {
  @include link-reset;

  display: block;

  svg {
    display: block;
    width: 100%;
  }
}
