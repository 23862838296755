.form-filter {
  display: flex;
  width: 100%;

  &--ext {
    .form-filter__field {
      margin-bottom: 20px;

      @media (min-width: $width-tablet-m) {
        margin-bottom: 25px;
        margin-right: 30px;
      }

      &--double {
        .form-filter__input:first-child {
          width: 105%;

          @media (min-width: $width-tablet-m) {
            width: 102%;
          }
        }
      }
    }

    .form-filter__select {
      overflow: hidden;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .form-filter__input {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .form-filter__submit {
      @include btn--transparent;

      margin-bottom: 20px;
      padding: 10px 35px;
      font-size: 11px;
      border-radius: 25px;

      @media (min-width: $width-tablet-m) {
        margin-bottom: 25px;
      }
    }
  }
}

.form-filter__field {
  flex-grow: 1;
  margin: 0;

  &--double {
    display: flex;
    align-items: center;
  }
}

.form-filter__input {
  @include input;

  min-height: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &:focus {
    outline: none;
  }
}

.form-filter__submit {
  @include label-submit;
}

.form-filter__select {
  flex-grow: 1;
}

.form-filter__dash {
  margin: 0 10px;
}
