@import "if-vars";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?w9o0sy') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?w9o0sy') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?w9o0sy##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}

.icon-keyboard_arrow_right {
  &:before {
    content: $icon-keyboard_arrow_right;
  }
}

.icon-keyboard_arrow_left {
  &:before {
    content: $icon-keyboard_arrow_left;
  }
}

.icon-phone_in_talk {
  &:before {
    content: $icon-phone_in_talk;
  }
}

.icon-close1 {
  &:before {
    content: $icon-close1;
  }
}

.icon-play_arrow {
  &:before {
    content: $icon-play_arrow;
  }
}

.icon-star-o {
  &:before {
    content: $icon-star-o;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-youtube-play {
  &:before {
    content: $icon-youtube-play;
  }
}

.icon-eye {
  &:before {
    content: $icon-eye;
  }
}

.icon-file-text2 {
  &:before {
    content: $icon-file-text2;
  }
}
