.area {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 430px;
  z-index: 10;
  color: #ffffff;
}

.area__container {
  position: absolute;
  bottom: 15px;
  right: 0;
  padding: 0 15px;
}

.area__map {
  position: relative;
  margin: 0 auto;
  width: 400px;

  @media (min-width: $width-map-icons-to-column) {
    width: 500px;
  }
}

.area__desc {
  position: absolute;
  top: -100%;
  left: -100%;
  z-index: 50;

  display: none;
  padding: 5px 10px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.025em;
  color: #000000;

  background-color: #ffffff;
  border-radius: 5px;
  transform: translate(-10%, -100%);
}

.area__list {
  @include list-reset;

  @media (min-width: $width-desktop) {
    display: flex;
  }

  @media (min-width: $width-map-icons-to-column) {
    position: absolute;
    top: 50%;
    right: 0;

    display: block;
    max-width: 100px;

    transform: translate(100%, -50%);
  }
}

.area__item {
  margin-right: 40px;

  @media (min-width: $width-map-icons-to-column) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.area__link {
  @include link-reset;

  display: block;
  text-align: center;
  transition: color 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: lighten($color-prime, 30%);
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

.area__title {
  margin: 0;
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: center;
  white-space: nowrap;
}

.area__point {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;

  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    .area__desc {
      display: block;
    }
  }

  &--1 {
    top: 150px;
    left: 285px;
  }

  &--2 {
    top: 85px;
    left: 240px;
  }

  &--3 {
    top: 130px;
    left: 200px;
  }

  &--4 {
    top: 175px;
    left: 230px;
  }

  &--5 {
    top: 258px;
    left: 173px;
  }

  &--6 {
    top: 285px;
    left: 145px;
  }

  &--7 {
    top: 315px;
    left: 60px;
  }

  &--8 {
    top: 250px;
    left: 145px;
  }

  &--9 {
    top: 465px;
    left: 265px;
  }

  &--10 {
    top: 275px;
    left: 220px;
  }

  &--11 {
    top: 390px;
    left: 445px;
  }

  &--12 {
    top: 180px;
    left: 180px;
  }

  &--13 {
    top: 220px;
    left: 280px;
  }

  &--14 {
    top: 370px;
    left: 110px;
  }

  &--15 {
    top: 360px;
    left: 65px;
  }

  &--16 {
    top: 225px;
    left: 340px;
  }

  &--17 {
    top: 330px;
    left: 165px;
  }

  &--18 {
    top: 420px;
    left: 305px;
  }

  &--19 {
    top: 500px;
    left: 235px;
  }

  &--20 {
    top: 290px;
    left: 170px;
  }

  &--21 {
    top: 450px;
    left: 180px;
  }

  &--22 {
    top: 335px;
    left: 130px;
  }

  &--23 {
    top: 440px;
    left: 280px;
  }

  &--24 {
    top: 175px;
    left: 310px;
  }

  &--25 {
    top: 210px;
    left: 180px;
  }

  &--26 {
    top: 270px;
    left: 250px;
  }

  &--27 {
    top: 335px;
    left: 220px;
  }

  &--28 {
    top: 470px;
    left: 225px;
  }

  &--29 {
    top: 325px;
    left: 310px;
  }

  &--30 {
    top: 130px;
    left: 160px;
  }

  &--31 {
    top: 258px;
    left: 165px;
  }

  &--32 {
    top: 360px;
    left: 355px;
  }

  &--33 {
    top: 350px;
    left: 310px;
  }

  &--34 {
    top: 330px;
    left: 250px;
  }

  &--35 {
    top: 385px;
    left: 130px;
  }

  &--36 {
    top: 375px;
    left: 140px;
  }

  &--37 {
    top: 400px;
    left: 210px;
  }

  &--38 {
    top: 340px;
    left: 235px;
  }

  &--39 {
    top: 390px;
    left: 340px;
  }

  &--40 {
    top: 100px;
    left: 280px;
  }

  &--41 {
    top: 355px;
    left: 145px;
  }

  &--42 {
    top: 465px;
    left: 435px;
  }

  &--43 {
    top: 320px;
    left: 150px;
  }

  &--44 {
    top: 380px;
    left: 150px;
  }

  &--45 {
    top: 340px;
    left: 65px;
  }

  &--46 {
    top: 392px;
    left: 105px;
  }

  &--47 {
    top: 425px;
    left: 175px;
  }

  &--47 {
    top: 430px;
    left: 180px;
  }

  &--48 {
    top: 400px;
    left: 125px;
  }

  &--49 {
    top: 400px;
    left: 120px;
  }

  &--50 {
    top: 390px;
    left: 345px;
  }

  &--51 {
    top: 380px;
    left: 60px;
  }

  &--52 {
    top: 107px;
    left: 280px;
  }

  &--53 {
    top: 305px;
    left: 150px;
  }

  &--54 {
    top: 340px;
    left: 220px;
  }

  &--55 {
    top: 375px;
    left: 270px;
  }

  &--56 {
    top: 345px;
    left: 315px;
  }

  &--57 {
    top: 395px;
    left: 100px;
  }

  &--58 {
    top: 260px;
    left: 275px;
  }
}
