.slider-pagination {
  display: flex;

  &--linked {
    .slider-pagination__bullet {
      background-color: $color-prime;
      transition: width 0.4s;

      &:active {
        background-color: $color-prime;
      }

      &--active {
        width: 100px;
      }
    }
  }
}

.slider-pagination__bullet {
  @include btn;

  display: block;
  margin-right: 10px;
  width: 30px;
  height: 30px;

  background-color: $color-prime;
  border: 1px solid $color-prime;
  border-radius: 15px;

  transition: background-color 0.2s;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    background-color: transparent;
  }

  &:last-child {
    margin-right: 0;
  }

  &--active {
    background-color: transparent;
  }
}

.slider-pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 1;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    color: $color-prime;
  }
}

