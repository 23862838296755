.news-section__list {
  @include list-reset;
  @include container;

  @media (min-width: $width-laptop) {
    padding-left: 0;
    padding-right: 0;
  }
}

.news-section__controls {
  @include container;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  display: none;
  width: 100%;
  transform: translate(-50%);

  @media (min-width: $width-laptop) {
    display: block;
  }
}

.news-section__control {
  @include slider-btn;

  transform-origin: center;
  outline: none;

  &--prev {
    transform: rotate(90deg) translateX(-100%);
  }

  &--next {
    transform: rotate(-90deg) translateX(100%);
  }
}
