.archives {
  position: relative;

  @media (min-width: $width-laptop) {
    width: 45.5%;
    flex-shrink: 0;
  }

  &::before {
    @media (min-width: $width-laptop) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 5px;

      background-color: $color-prime;
      border-radius: 3px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    bottom: 0;
    z-index: -1;

    background-color: #f7f7f7;

    @media (min-width: $width-laptop) {
      background-color: $color-body;
    }
  }
}

.archives__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 30px 0;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  color: #000000;
  text-transform: uppercase;

  @media (min-width: $width-tablet-s) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 20px;
    padding: 0 30px;
  }

  svg {
    flex-shrink: 0;

    @media (min-width: $width-laptop) {
      display: none;
    }
  }
}

.archives__list {
  @include list-reset;
}

.archives__link {
  @include link-reset;

  display: block;
  padding: 10px 0;

  transition:
    color 0.2s,
    background-color 0.2s;

  @media (min-width: $width-laptop) {
    padding-left: 30px;
    padding-right: 30px;
  }

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background-color: $color-prime;
    outline: none;
  }
}

.archives__name {
  margin: 0;
  margin-bottom: 10px;

  font-family: "Avanti", "Arial", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.archives__text {
  margin: 0;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.005em;
}

.archives__content {
  display: none;

  @media (min-width: $width-laptop) {
    display: block;
    max-height: 360px;
  }
}
