.article {
  padding: 20px 0;

  @media (min-width: $width-tablet-s) {
    padding: 30px 0;
  }

  @media (min-width: $width-laptop) {
    display: flex;
    align-items: center;
  }
}

.article__image {
  margin: 0;
  margin-bottom: 15px;
  width: 60px;

  @media (min-width: $width-tablet-m) {
    flex-shrink: 0;
    width: 90px;
  }

  @media (min-width: $width-laptop) {
    margin-right: 20px;
  }

  img {
    border-radius: 5px;
  }
}

.article__header {
  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
}

.article__title {
  margin: 0;
  margin-bottom: 10px;
}

.article__date {
  display: block;
  margin-bottom: 10px;
}

.article__desc {
  margin: 0;
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.005em;
}

.article__link {
  @include link-reset;

  color: $color-prime;
  text-transform: uppercase;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }

  &:active {
    opacity: 0.7;
  }
}
