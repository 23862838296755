.videos {
  position: relative;

  @media (min-width: $width-tablet-s) {
    padding-bottom: 45px;
  }

  @media (min-width: $width-tablet-m) {
    padding-bottom: 0;
  }

  @media (min-width: $width-laptop) {
    flex-shrink: 0;
    padding-top: 75px;
    width: 45.5%;
  }

  &::before {
    content: "";
    position: absolute;
    top: -25px;
    right: -15px;
    bottom: 0;
    left: -15px;
    z-index: -1;
    background-color: $color-bg-stripe;

    @media (min-width: $width-laptop) {
      left: -100vw;
      right: 0;
    }
  }
}

.videos__list {
  @include list-reset;

  @media (min-width: $width-tablet-m) {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: $width-laptop) {
    display: block;
  }
}

.videos__title {
  margin: 0;
  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.videos__time {
  display: block;
  margin-bottom: 20px;

  font-family: "Avanti", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.025em;
}

.videos__text {
  margin: 0;
  margin-bottom: 20px;
  margin-left: auto;
  max-width: 180px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.025em;
}

.videos__preview {
  position: relative;

  display: block;
  min-height: 180px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: $width-tablet-s) {
    width: 50%;
  }

  @media (min-width: $width-tablet-m) {
    width: 100%;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;

    .videos__icon {
      color: red;
    }
  }
}

.videos__icon {
  position: absolute;
  top: 50%;
  left: 50%;

  font-size: 62px;
  color: #505050;

  transform: translate(-50%, -50%);

  transition: color 0.2s;
}

.videos__item {
  margin-bottom: 25px;
  padding-top: 25px;
  text-align: right;

  @media (min-width: $width-tablet-s) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-top: 35px;
  }

  @media (min-width: $width-tablet-m) {
    width: 45%;
  }

  @media (min-width: $width-laptop) {
    flex-direction: row;
    margin-bottom: 30px;
    padding-top: 0;
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.videos__desc {
  @media (min-width: $width-tablet-s) {
    flex-grow: 1;
    margin-right: 35px;
  }

  @media (min-width: $width-tablet-m) {
    margin-right: 0;
  }

  @media (min-width: $width-laptop) {
    margin-right: 20px;
  }
}
