.request {
  @media (min-width: $width-tablet-m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.request__title {
  margin: 0;
  margin-bottom: 10px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 0;
    font-size: 16px;
  }
}

.request__link {
  @include btn--black;

  padding-left: 15px;
  padding-right: 15px;
}
