.review {
  margin: 0;
}

.review__photo {
  margin: 0;
  max-width: 115px;
  max-height: 115px;

  @media (min-width: $width-tablet-s){
    margin-right: 20px;
  }
}

.review__photo-src {
  border-radius: 5px;
}

.review__author {
  display: block;
  margin-bottom: 5px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.review__date {
  display: block;
  margin-bottom: 5px;

  font-family: "Avanti", "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;

  @media (min-width: $width-tablet-s){
    margin-bottom: 0;
  }
}

.review__text {
  margin: 0;
  margin-bottom: 10px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.6;

  @media (min-width: $width-tablet-m) {
    font-size: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.review__header {
  margin-bottom: 20px;

  @media (min-width: $width-tablet-s) {
    display: flex;
  }
}

.review__info {
  @media (min-width: $width-tablet-s) {
    align-self: flex-end;
  }
}
