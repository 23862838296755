.page-search__wrapper {
  @include container;
}

.page-search__search {
  margin-bottom: 45px;
  max-width: 900px;

  &--deal {
    margin-bottom: 30px;

    @media (min-width: $width-tablet-m) {
      margin-bottom: 35px;
    }

    @media (min-width: $width-laptop) {
      margin-bottom: 50px;
    }
  }
}

.page-search__filter {
  margin-bottom: 30px;

  &--deal {
    margin-bottom: -30px;

    @media (min-width: $width-tablet-m) {
      @include container;
      margin-bottom: -30px;
    }
  }
}

.page-search__list {
  @include list-reset;
}

.page-search__item {
  &:nth-child(2n) {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: calc((100vw - 100% - 17px) / (-2));
      bottom: 0;
      left: calc((100vw - 100% - 17px) / (-2));

      background-color: transparentize($color-prime, 0.8);
    }
  }
}

.page-search__pagination {
  margin: 30px 0;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 50px;
  }

  @media (min-width: $width-laptop) {
    margin: 60px 0;
  }
}

.page-search__content {
  @include table;

  @media (min-width: $width-desktop) {
    @include container;
    margin-bottom: 50px;
  }
}

.page-search__banner {
  margin-bottom: 35px;
}

.page-search__extended {
  margin-bottom: 35px;

  @media (min-width: $width-laptop) {
    margin-bottom: 40px;
  }
}
