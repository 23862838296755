.banners__list {
  @include list-reset;
}

// .banners__item {
//   max-width: 95%;
//
//   @media (min-width: $width-tablet-s) {
//     max-width: 70%;
//   }
//
//   @media (min-width: $width-tablet-m) {
//     max-width: 40%;
//   }
//
//   @media (min-width: $width-laptop) {
//     max-width: 30%;
//   }
// }

.banners__item {
  width: auto;
}

.banners__controls {
  @include container;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  display: none;
  width: 100%;
  transform: translate(-50%, -50%);

  @media (min-width: $width-laptop) {
    display: block;
  }
}

.banners__control {
  @include slider-btn;
}

.banners__image {
  width: auto;
  height: 200px;
}
