.columns {
  padding-top: 25px;

  &--start {
    .columns__wrapper {
      @media (min-width: $width-laptop) {
        align-items: flex-start;
      }
    }
  }
}

.columns__wrapper {
  @include container;

  @media (min-width: $width-laptop) {
    display: flex;
  }
}
