.contacts {
  display: flex;
}

.contacts__data {
  box-sizing: border-box;
  padding: 15px 35px;
  width: 360px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  text-align: right;
}

.contacts__city {
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.contacts__text {
  margin: 0;
  margin-bottom: 20px;
}

.contacts__phone {
  margin-bottom: 20px;

  .contacts__text {
    margin-bottom: 5px;
  }
}

.contacts__shedule {
  font-weight: 400;
  font-size: 14px;
}

.contacts__icon {
  margin-right: 5px;
  font-size: 12px;
  vertical-align: middle;
}

.contacts__map {
  flex-grow: 1;
}
