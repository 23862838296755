.version {
  &--inner {
    .version__link {
      color: #000000;
    }
  }

  @media (min-width: $width-tablet-s) {
    display: flex;
  }
}

.version__link {
  @include link-reset;

  display: flex;
  margin-bottom: 5px;

  font-family: "Avanti", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.025em;

  transition: color 0.4s;

  @media (min-width: $width-laptop) {
    margin-bottom: 0;
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }

  &:last-child {
    margin-bottom: 0;

    &::after {
      @media (min-width: $width-tablet-s) {
        content: none;
      }
    }
  }

  &--poor-vis {
    display: none;

    @media (min-width: $width-laptop) {
      display: block;
    }
  }

  &--active {
    .version__text {
      text-decoration: underline;
    }
  }

  &::after {
    @media (min-width: $width-tablet-s) {
      content: "/";

      display: inline-block;
      margin: 0 15px;
      text-decoration: none;
    }
  }
}

.version__separator {
  display: none;

  @media (min-width: $width-tablet-m) {
    display: block;
  }
}
