.documents__title {
  margin: 0;
  margin-bottom: 35px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (min-width: $width-laptop) {
    padding: 0 35px;
  }
}

.documents__list {
  @include list-reset;

  //@media (min-width: $width-laptop) {
  //  display: block;
  //  padding-bottom: 50px;
  //}
}

.documents__item {
  @media (min-width: $width-laptop) {
    //margin-bottom: 35px;
    width: auto;
    height: auto;
  }

  //&:last-child {
  //  @media (min-width: $width-laptop) {
  //    margin-bottom: 0;
  //  }
  //}
}

.documents__link {
  @include link-reset;

  display: block;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;

  @media (min-width: $width-laptop) {
    padding: 10px 35px;
  }

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background-color: $color-prime;

    .documents__icon {
      color: #ffffff;
    }
  }

  &:active {
    opacity: 0.7;
  }
}

.documents__icon {
  display: block;
  margin-bottom: 15px;

  font-size: 66px;
  color: $color-prime;
}

.documents__scroll {
  //margin: 13px 0;
  margin-bottom: 13px;
  height: 4px;
  background-color: $color-prime;
}

.documents__drag {
  position: relative;
  top: -13px;

  width: 30px;
  height: 30px;

  background-color: $color-prime;
  border-radius: 50%;
  cursor: pointer;
}
