.contact {
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
}

.contact__title {
  margin: 0;
  text-transform: uppercase;
}

.contact__list {
  @include list-reset;
  // display: flex;
}

.contact__item {
  padding-right: 8px;
  // white-space: nowrap;

  &::after {
    content: "/";
    margin-left: 7px;
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.contact__phones {
  display: flex;
}

.contact__phone {
  @include link-reset;
}

.contact__address {
  margin: 0;
}

.contact__link {
  @include link-reset;

  display: block;
  padding: 20px 60px;
  outline: none;
  transition:
    color 0.2s,
    background-color 0.2s;

  @media (max-width: $width-tablet-s) {
    padding: 20px;
  }

  @media (max-width: 360px) {
    padding: 10px;
  }

  &:hover,
  &:focus,
  &:active,
  &--active {
    color: #ffffff;
    background-color: $color-prime;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}
