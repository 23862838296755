.page-calendar__wrapper {
  @include container;
}

.page-calendar__list {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 40px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 25px;
  }
}

.page-calendar__pagination {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 40px;
  }
}

.page-calendar__filter {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 40px;
  }

  @media (min-width: $width-tablet-m) {
    display: flex;
  }
}

.page-calendar__search {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 0;
    margin-right: 25px;
  }

  @media (min-width: $width-tablet-m) {
    margin-right: 30px;
  }

  &:last-child {
    margin-bottom: 0;

    @media (min-width: $width-tablet-m) {
      margin-right: 0;
    }
  }

  &--year {
    @media (min-width: $width-tablet-m) {
      width: 230px;
    }
  }

  &--months {
    @media (min-width: $width-tablet-m) {
      width: 320px;
    }
  }
}
