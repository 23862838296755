.page-guestbook__wrapper {
  @include container;
}

.page-guestbook__guest {
  margin-bottom: 45px;
}

.page-guestbook__review {
  margin-bottom: 45px;

  @media (min-width: $width-laptop) {
    box-sizing: border-box;
    width: 50%;
  }

  &:nth-child(2n+1) {
    @media (min-width: $width-laptop) {
      padding-right: 70px;
    }
  }

  &:nth-child(2n) {
    @media (min-width: $width-laptop) {
      padding-left: 70px;
    }
  }
}

.page-guestbook__pagination {
  margin-bottom: 40px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 50px;
  }
}

.page-guestbook__reviews {
  @media (min-width: $width-laptop) {
    display: flex;
    flex-wrap: wrap;
  }
}
