.page-contacts__wrapper {
  @include container;
}

.page-contacts__news {
  margin-bottom: 80px;

  @media (min-width: $width-laptop) {
    @include container;
    margin-bottom: 50px;
  }
}
