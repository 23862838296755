.extended {
  padding-top: 60px;
  padding-bottom: 20px;
  background-color: $color-bg-stripe;

  @media (min-width: $width-tablet-m) {
    padding-bottom: 25px;
  }

  &--invent {
    .extended__search {
      @media (min-width: $width-laptop) {
        flex-wrap: nowrap;
      }
    }
  }
}

.extended__wrapper {
  @include container;
}

.extended__search {
  display: block;

  @media (min-width: $width-tablet-m) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
