.favorites__form {
  input[type="checkbox"] {
    display: none;

    & ~ .favorites__icon {
      &--active {
        display: none;
      }

      &--passive {
        display: inline-block;
      }
    }

    &:checked {
      & ~ .favorites__icon {
        &--active {
          display: inline-block;
        }

        &--passive {
          display: none;
        }
      }
    }
  }
}

.favorites__label {
  display: inline-block;
  padding: 0;
  cursor: pointer;

  &::before {
    content: none;
  }

  &::after {
    content: none;
  }
}

.favorites__icon {
  font-size: 22px;
  color: $color-prime;
}
