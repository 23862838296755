.pagination {
  display: flex;
}

.pagination__list {
  @include list-reset;

  display: flex;
}

.pagination__item {
  margin-right: 25px;
}

.pagination__link {
  @include link-reset;

  display: block;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.025em;

  transition: color 0.2s;

  &:hover,
  &:focus,
  &:active,
  &--active {
    color: $color-prime;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }

  &--active {
    text-decoration: underline;
  }

  &--prev {
    margin-right: 25px;
  }

  &--disabled {
    display: none;
  }
}
