.banner {
  @media (min-width: $width-tablet-m) {
    @include container;
  }
}

.banner__wrapper {
  padding: 30px 15px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  color: #ffffff;

  background-color: #2f2320;
  background-image: url("../img/aside-bg-mob.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: $width-tablet-m) {
    background-image: url("../img/aside-bg-tab.jpg");
  }

  @media (min-width: $width-desktop) {
    display: flex;
    padding: 15px 20px;
    background-image: url("../img/aside-bg.jpg");
  }
}

.banner__promo {
  margin: 0;
  margin-bottom: 15px;

  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  text-transform: uppercase;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 30px;
    text-align: center;
  }

  @media (min-width: $width-desktop) {
    margin-right: 15px;
    margin-bottom: 0;
    width: 275px;
    text-align: left;
  }
}

.banner__list {
  @include list-reset;

  @media (min-width: $width-tablet-s) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.banner__title {
  margin: 0;
  margin-bottom: 10px;
  width: 100%;

  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  text-transform: lowercase;
  letter-spacing: 0.025em;

  b {
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.bannner__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 15px 20px;
  max-width: 240px;
  border: 1px solid #ffffff;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 0;
    margin-right: 15px;
    padding: 15px;
    width: 140px;
  }

  @media (min-width: $width-tablet-m) {
    margin-right: 60px;
    width: 200px;
  }

  @media (min-width: $width-laptop) {
    margin-right: 25px;
    width: 230px;
  }

  &:last-child {
    margin-bottom: 0;

    @media (min-width: $width-tablet-s) {
      margin-right: 0;
    }
  }
}

.banner__price {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-s) {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 0;
    width: auto;
  }
}

.banner__btn {
  @include btn--prime;
}
