.page-novelty {
  @media (min-width: $width-tablet-s) {
    margin-bottom: 15px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 20px;
  }
}

.page-novelty__wrapper {
  @include container;

  width: 100%;

  @media (min-width: $width-laptop) {
    position: relative;
  }
}

.page-novelty__image {
  margin: 0;
  margin-bottom: 15px;
  max-width: 315px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 20px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 25px;
    max-width: 410px;
  }

  @media (min-width: $width-laptop) {
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

.page-novelty__text {
  margin: 0;
  margin-bottom: 15px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.6;
  letter-spacing: 0.025em;
  word-wrap: break-word;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 20px;
    font-size: 16px;
  }

  &:last-child {
    margin-bottom: 20px;

    @media (min-width: $width-laptop) {
      margin-bottom: 30px;
    }
  }
}

.page-novelty__banners {
  margin-bottom: 20px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 25px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 30px;
  }
}

.page-novelty__time {
  display: block;
  font-family: "Arial", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.025em;
}

.page-novelty__header {
  margin-bottom: 25px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 30px;
  }

  @media (min-width: $width-laptop) {
    position: absolute;
    top: 0;
    right: 15px;
    transform: translateY(-200%);
  }
}

.page-novelty__type {
  @include btn--black;
}
