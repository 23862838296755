.page-title {
  margin: 0;
  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: $color-prime;
  word-wrap: break-word;
}
