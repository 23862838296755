.page-fond {
  p:not([class]) {
    margin: 0;
    margin-bottom: 15px;

    font-family: "Proxima Nova", "Arial", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.025em;
  }
}

.page-fond__wrapper {
  @include container;
}

.page-fond__content {
  margin-bottom: 35px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 40px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 50px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 60px;
  }
}

.page-fond__header {
  margin-bottom: 15px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 20px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 30px;
  }
}

.page-fond__text {
  margin-bottom: 10px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.6;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 20px;
  }

  @media (min-width: $width-tablet-m) {
    font-size: 14px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 30px;
  }

  p {
    margin: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.page-fond__guides {
  margin-bottom: 20px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 30px;
  }

  @media (min-width: $width-laptop) {
    margin-right: auto;
    margin-bottom: 0;
    width: 360px;
  }
}

.page-fond__rubrics {
  width: 360px;
}

.page-fond__title {
  margin: 0;
  margin-bottom: 5px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  text-transform: uppercase;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 10px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 15px;
  }
}

.page-fond__list {
  @include list-reset;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.3;

  li {
    margin-bottom: 5px;

    @media (min-width: $width-tablet-s) {
      margin-bottom: 10px;
    }

    @media (min-width: $width-tablet-m) {
      font-size: 14px;
    }

    @media (min-width: $width-laptop) {
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.page-fond__table {
  min-width: 100%;

  svg {
    margin-bottom: 0;
    margin-right: 10px;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    vertical-align: middle;

    @media (min-width: $width-tablet-m) {
      font-size: 23px;
    }
  }

  b {
    vertical-align: middle;
  }

  td {
    white-space: nowrap;
  }
}

.page-fond__footer {
  @media (min-width: $width-laptop) {
    display: flex;
  }
}

.page-fond__service {
  margin-bottom: 35px;
}

.page-fond__columns {
  @media (min-width: $width-tablet-m) {
    display: flex;
    align-items: flex-start;
  }

  .page-fond__text {
    @media (min-width: $width-tablet-m) {
      margin-bottom: 0;
      margin-right: 50px;
    }

    @media (min-width: $width-laptop) {
      margin-left: 80px;
    }
  }
}
