@mixin container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 15px;
  min-width: $width-mobile;
  max-width: $width-desktop;
}

@mixin container--modal {
  @include container;
  max-width: 650px;
}

@mixin btn {
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
  border: none;

  background-color: transparent;
  cursor: pointer;
}

@mixin link-reset {
  color: inherit;
  text-decoration: inherit;
}

@mixin btn--white {
  @include link-reset;
  @include btn;

  padding: 10px 15px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.05em;
  color: $color-prime;
  text-transform: uppercase;

  border: 1px solid #ffffff;
  border-radius: 20px;
  background-color: #ffffff;

  transition:
    color 0.2s,
    background-color 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background-color: $color-prime;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

@mixin btn--prime {
  @include btn--white;

  color: #ffffff;
  background-color: $color-prime;
  border-color: $color-prime;

  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
    background-color: #ffffff;
  }
}

@mixin btn--transparent {
  @include link-reset;
  @include btn;

  padding: 5px 10px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 8px;
  color: #000000;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  border: 1px solid #000000;
  border-radius: 10px;

  transition:
    color 0.2s,
    background-color 0.2s;

  &:hover,
  &:focus,
  &:active,
  &--active {
    color: #ffffff;
    background-color: #000000;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

@mixin btn--black {
  @include btn--transparent;

  color: #ffffff;
  background-color: #000000;

  &:hover,
  &:focus,
  &:active,
  &--active {
    color: #000000;
    background-color: #ffffff;
  }
}

@mixin label-link {
  @include link-reset;

  padding: 10px 20px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.025em;
  text-transform: uppercase;

  background-color: $color-prime;
  border: 1px solid $color-prime;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  transition:
    color 0.2s,
    background-color 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
    background-color: $color-body;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

@mixin label-link--black {
  @include label-link;

  padding: 5px 15px;

  font-size: 8px;
  white-space: nowrap;

  background-color: #000000;
  border-color: #000000;

  &:hover,
  &:focus,
  &:active {
    color: #000000;
  }
}

@mixin label-submit {
  @include label-link--black;

  padding: 10px 15px;
  font-size: 11px;
  line-height: 20px;
  cursor: pointer;
}

@mixin label-submit--trasparent {
  @include label-submit;

  color: #000000;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background-color: #000000;
  }
}

@mixin blue-circle-btn {
  @include btn;

  width: 60px;
  height: 60px;

  color: #ffffff;
  line-height: 60px;
  font-size: 22px;
  text-align: center;

  background-color: $color-prime;
  border: 1px solid transparent;
  border-radius: 50%;

  transition:
    color 0.2s,
    background-color 0.2s,
    border-color 0.2s;

  &:hover,
  &:active,
  &:focus {
    color: $color-prime;
    background-color: $color-body;
    border-color: $color-prime;
    outline: none;
  }

  svg {
    vertical-align: middle;
  }
}

@mixin capsule-btn {
  @include link-reset;
  @include btn;

  display: flex;
  align-items: center;
  padding: 20px;
  height: 60px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;

  border: 1px solid transparent;
  border-radius: 30px;
  background-color: $color-body;

  transition:
    color 0.2s,
    background-color 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background-color: $color-prime;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

@mixin capsule-btn--light {
  @include capsule-btn;

  color: $color-prime;
  border-color: $color-prime;
}

@mixin capsule-btn--dark {
  @include capsule-btn;

  color: #ffffff;
  background-color: $color-prime;
  border-color: $color-prime;

  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
    background-color: $color-body;
  }
}

@mixin slider-btn {
  @include btn;

  position: absolute;
  color: $color-prime;
  transform-origin: top;

  &:active {
    opacity: 0.7;
  }

  &--prev {
    left: 0;
    transform: rotate(90deg);
  }

  &--next {
    right: 0;
    transform: rotate(-90deg);
  }

  &--disabled {
    display: none;
  }

  svg {
    width: 50px;
    height: auto;
  }
}

@mixin list-reset {
  margin: 0;
  padding: 0;

  list-style: none;
}

@mixin input-reset {
  display: block;
  box-sizing: border-box;
  width: 100%;

  border: none;
  background-color: transparent;
}

@mixin input {
  @include input-reset;

  padding: 10px 20px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.005em;
  color: #000000;

  background-color: #e7e9eb;
  border-radius: 5px;
  resize: none;

  &::placeholder {
    color: rgba(0, 0, 0, 0.8);
  }

  &:focus {
    background-color: transparentize($color-prime, 0.8);
    outline: none;

    &::placeholder {
      opacity: 0;
    }
  }

  &:disabled {
    background-color: transparent;
  }
}

@mixin fieldset-reset {
  margin: 0;
  padding: 0;
  border: none;
}

@mixin table {
  overflow-x: auto;
}

@mixin clearfix {
  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}
