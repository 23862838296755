.breadcrumbs {
  margin: 25px 0;
}

.breadcrumbs__wrapper {
  @include container;
}

.breadcrumbs__list {
  @include list-reset;

  @media (min-width: $width-tablet-m) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.breadcrumbs__item {
  margin-bottom: 20px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 30px;
    margin-right: 20px;
  }

  &:first-child {
    position: relative;
    margin-left: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 100%;

      width: 1px;
      height: 40px;

      background-color: $color-prime;
    }
  }

  &:last-child {
    margin-bottom: 0;

    @media (min-width: $width-tablet-m) {
      margin-bottom: 30px;
      margin-right: 0;
    }
  }
}

.breadcrumbs__link {
  @include label-link;
  display: inline-block;
}
