.novelty {
  @include link-reset;

  display: block;

  &:hover,
  &:focus,
  &:active {
    outline: none;

    .novelty__title,
    .novelty__time {
      color: $color-prime;
    }
  }

  &:active {
    opacity: 0.7;
  }
}

.novelty__pic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  min-height: 160px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.novelty__type {
  @include label-link--black;

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;
    background-color: #000000;
    cursor: pointer;
    opacity: 1;
  }
}

.novelty__title {
  margin: 0;
  margin-bottom: 10px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.025em;

  transition: color 0.2s;
}

.novelty__time {
  display: block;
  font-family: "Avanti", "Arial", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  transition: color 0.2s;
}
