.service {
  @media (min-width: $width-desktop) {
    margin-bottom: 10px;
  }

  &:last-child {
    @media (min-width: $width-desktop) {
      margin-bottom: 0;
    }
  }

  &--dropable {
    background-color: #f3f5f7;

    .service__content {
      display: none;

      @media (min-width: $width-tablet-s) {
        padding-top: 0;
      }
    }

    .service__title {
      cursor: pointer;

      @media (min-width: $width-tablet-s) {
        padding: 30px 0;
      }

      @media (min-width: $width-tablet-m) {
        padding: 45px 0;
      }
    }
  }
}

.service__wrapper {
  @include container;
}

.service__title {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 15px 0;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.1;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (min-width: $width-tablet-s) {
    align-items: center;
    padding-top: 30px;
  }

  @media (min-width: $width-tablet-m) {
    padding-top: 45px;
    padding-bottom: 20px;
  }

  &.active {
    .service__drop {
      transform: rotate(-90deg);
    }
  }
}

.service__icon {
  margin-right: 10px;
  color: #575757;
  font-size: 35px;
}

.service__desc {
  margin: 0;
  margin-bottom: 15px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.5;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 25px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 30px;
    font-size: 16px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 45px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.service__drop {
  align-self: center;
  margin-left: auto;
  transform: rotate(90deg);
  transition: transform 0.4s;
}

.service__links {
  margin: 0;
  margin-bottom: 15px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 25px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 35px;
  }
}

.service__btn {
  @include btn--black;

  margin-bottom: 20px;
  padding: 10px 15px;

  font-size: 11px;
  line-height: 1.3;

  border-radius: 20px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.service__list {
  @include list-reset;
}

.service__item {
  margin-bottom: 25px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 35px;
  }
}

.service__content {
  padding-top: 15px;
  padding-bottom: 20px;

  @media (min-width: $width-tablet-m) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
