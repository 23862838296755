.payments {
  padding: 50px 0;
  background-color: $color-bg-stripe;
}

.payments__wrapper {
  @include container;
}

.payments__history {
  @include table;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.025em;
}

.payments__title {
  margin: 0;
  margin-bottom: 25px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.payments__table {
  min-width: 100%;
}
