.page-registry__wrapper {
  @include container;
}

.page-registry__list {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 40px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 25px;
  }
}

.page-registry__pagination {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 40px;
  }
}
