.faq__wrapper {
  @include container;
}

.faq__list {
  @include list-reset;
}

.faq__item {
  margin-bottom: 55px;

  @media (min-width: $width-tablet-s) {
    position: relative;
    margin-bottom: 40px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 50px;
  }

  &::before {
    @media (min-width: $width-tablet-s) {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      width: 1px;

      background-color: #000000;
    }
  }
}

.faq__type {
  @include label-link--black;

  display: inline-block;
  margin-left: -15px;
  margin-bottom: 10px;

  @media (min-width: $width-tablet-s) {
    margin-left: 0;
    margin-bottom: 0;
  }

  @media (min-width: $width-tablet-m) {
    margin-right: 10px;
  }
}

.faq__filter {
  margin-bottom: 25px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 15px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 20px;
  }
}

.faq__title {
  margin: 0;
  margin-bottom: 25px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  color: #000000;
  text-transform: uppercase;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 0;
    padding: 15px;
  }

  @media (min-width: $width-tablet-m) {
    padding: 0;
    font-size: 16px;
  }
}

.faq__text {
  margin: 0;
  margin-bottom: 15px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 11px;
  line-height: 1.6;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-s) {
    padding: 25px 15px;
    padding-bottom: 35px;
    font-size: 14px;
    background-color: #f3f5f7;
  }

  @media (min-width: $width-tablet-m) {
    padding: 30px;
    padding-bottom: 40px;
  }

  @media (min-width: $width-laptop) {
    padding-top: 35px;
    padding-left: 60px;
    padding-right: 60px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.faq__header {
  @media (min-width: $width-tablet-s) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: $width-tablet-m) {
    flex-direction: row;
    margin-bottom: 20px;
  }

  @media (min-width: $width-laptop) {
    flex-direction: row;
    margin-bottom: 15px;
  }
}
