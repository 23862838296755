.detail {
  position: relative;

  box-sizing: border-box;
  margin-bottom: 5px;
  padding: 20px;
  padding-bottom: 40px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: $width-tablet-m) {
    flex-shrink: 0;
    width: 250px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;

    width: 4px;

    background-color: $color-prime;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.4);
  }
}

.detail__more {
  @include label-link;

  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
}

.detail__desc {
  position: relative;
  z-index: 10;

  margin: 0;
  margin-bottom: 20px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.025em;
  color: #ffffff;
}
