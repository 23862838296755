.page-room__wrapper {
  @include container;
}

.page-room__banner {
  margin-bottom: 20px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 35px;
  }
}

.page-room__content {
  @include table;

  margin-bottom: 20px;

  .page-room__wrapper {
    padding: 0;
  }
}

.page-room__text {
  margin-bottom: 30px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-m) {
    font-size: 14px;
  }

  p {
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.page-room__scan {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-s) {
    display: flex;
    margin-bottom: 35px;
  }

  @media (min-width: $width-desktop) {
    margin-bottom: 45px;
  }
}

.page-room__search {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.page-room__enhanced {
  @include btn--black;

  padding: 10px 15px;
  font-size: 11px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px;

  @media (min-width: $width-tablet-s) {
    min-width: 190px;
  }
}

.page-room__pagination {
  margin: 30px 0;

  @media (min-width: $width-tablet-s) {
    margin: 40px 0;
  }

  @media (min-width: $width-desktop) {
    margin: 45px 0;
  }
}
