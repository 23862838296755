@font-face {
  font-family: "ArialMT";
  src: local("ArialMT"),
    url("../fonts/subset-ArialMT.woff2") format("woff2"),
    url("../fonts/subset-ArialMT.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AvantGardeCTT";
  src: local("AvantGardeCTT Bold"),
    local("AvantGardeCTT-Bold"),
    url("../fonts/subset-AvantGardeCTT-Bold.woff2") format("woff2"),
    url("../fonts/subset-AvantGardeCTT-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Avanti";
  src: local("Avanti"),
    url("../fonts/subset-Avanti.woff2") format("woff2"),
    url("../fonts/subset-Avanti.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Light"),
    local("ProximaNova-Light"),
    url("../fonts/subset-ProximaNova-Light.woff2") format("woff2"),
    url("../fonts/subset-ProximaNova-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Semibold"),
    local("ProximaNova-Semibold"),
    url("../fonts/subset-ProximaNova-Semibold.woff2") format("woff2"),
    url("../fonts/subset-ProximaNova-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Regular"),
    local("ProximaNova-Regular"),
    url("../fonts/subset-ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/subset-ProximaNova-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
