.page-footer {
  margin-top: auto;
  padding: 30px 0;
  color: #ffffff;
  background-color: $color-prime;
}

.page-footer__wrapper {
  @include container;

  @media (min-width: $width-laptop) {
    display: flex;
    justify-content: space-between;
  }
}

.page-footer__logo {
  margin-bottom: 15px;
  color: #ffffff;
  transition: color 0.2s;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 40px;
    width: 50%;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 10px;
    width: 100%;
  }

  &:hover,
  &:focus,
  &:active {
    color: #000000;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

.page-footer__shedule {
  @include btn--white;

  margin-bottom: 40px;

  @media (min-width: $width-laptop) {
    margin-bottom: 0;
    margin-right: auto;
  }
}

.page-footer__social {
  margin: 0;
  margin-bottom: 40px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 0;
    width: 50%;
  }

  a {
    &:hover,
    &:focus,
    &:active {
      color: #000000;
    }
  }
}

.page-footer__copyright {
  margin: 0;

  @media (min-width: $width-tablet-s) {
    width: 50%;
    text-align: right;
  }

  @media (min-width: $width-laptop) {
    text-align: left;
  }
}

.page-footer__links {
  display: none;

  @media (min-width: $width-laptop) {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    max-width: 680px;
  }

  .page-footer__list {
    margin-right: 15px;
  }
}

.page-footer__info {
  @media (min-width: $width-tablet-s) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: $width-laptop) {
    margin-right: 30px;
    max-width: 330px;
  }
}

.page-footer__list {
  @include list-reset;

  max-width: 230px;
}

.page-footer__item {
  margin-bottom: 10px;
}

.page-footer__link {
  @include link-reset;

  display: block;
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.35;
  letter-spacing: 0.05em;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

.page-footer__desc {
  display: flex;
  align-items: center;

  @media (min-width: $width-tablet-s) {
    width: 50%;
  }
}

.page-footer__arms {
  flex-shrink: 0;
  margin: 0;
  margin-right: 10px;
  width: 50px;

  svg {
    width: 100%;
    height: auto;
  }
}

.page-footer__text {
  margin: 0;
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.2;
}
