.page-pointer__wrapper {
  @include container;
}

.page-pointer__search {
  margin-bottom: 40px;
  max-width: 800px;

  @media (min-width: $width-tablet-m) {
    margin-bottom: 60px;
  }

  & > *:first-child {
    margin-right: 25px;
  }
}

.page-pointer__title {
  margin: 0;
  margin-bottom: 30px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.1;
  letter-spacing: 0.025em;
  text-transform: uppercase;

  @media (min-width: $width-laptop) {
    margin-bottom: 35px;
  }
}

.page-pointer__content {
  @media (min-width: $width-tablet-m) {
    margin-bottom: 50px;
  }
}

.page-pointer__list {
  @include list-reset;
}

.page-pointer__item {
  margin-bottom: 50px;

  @media (min-width: $width-tablet-m) {
    display: flex;
    margin-bottom: 30px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 45px;
  }

  &:last-child {
    @media (min-width: $width-tablet-m) {
      margin-bottom: 0;
    }
  }
}

.page-pointer__photo {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  // width: 200px;

  @media (min-width: $width-tablet-m) {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.page-pointer__image {
  margin: 0;
  width: 60px;
  height: 60px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  cursor: pointer;
}

.page-pointer__text {
  margin: 0;
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-m) {
    font-size: 16px;
  }
}

.page-pointer__pagination {
  margin-bottom: 35px;
}
