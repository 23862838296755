.social__link {
  @include link-reset;

  margin-right: 15px;
  font-size: 18px;

  transition: color 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }

  &:last-child {
    margin-right: 0;
  }
}
