.header {
  @media (min-width: $width-tablet-s) {
    display: flex;
    flex-wrap: wrap;
  }
}

.header__option {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;

  @media (min-width: $width-tablet-s) {
    margin-right: 40px;
    margin-bottom: 20px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.header__cell {
  margin-bottom: 10px;

  @media (min-width: $width-tablet-s) {
    margin-right: 20px;
  }

  svg {
    font-size: 22px;
    color: $color-prime;
  }
}

.header__key {
  margin-right: 10px;
  font-family: "Avanti", "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.1;
  white-space: nowrap;
}

.header__value {
  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.page-fond__desc {
  @include table;

  .page-fond__wrapper {
    padding: 0;
  }
}
