.not-found {
  padding: 30px 0;

  @media (min-width: $width-laptop) {
    position: relative;
    padding-left: 50%;
  }
}

.not-found__wrapper {
  @include container;

  @media (min-width: $width-tablet-m) {
    display: flex;
    align-items: center;
  }

  @media (min-width: $width-laptop) {
    display: block;
  }
}

.not-found__text {
  margin: 0;
  margin-bottom: 30px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 178px;
  line-height: 1;
  color: $color-prime;
  text-align: center;

  @media (min-width: $width-tablet-s) {
    font-size: 274px;
  }
}

.not-found__list {
  @include list-reset;

  margin-bottom: 30px;
  margin-left: 30px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 50px;
  }

  @media (min-width: $width-tablet-m) {
    margin-left: 50px;
  }
}

.not-found__item {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.not-found__link {
  @include link-reset;

  position: relative;
  padding-left: 20px;
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $color-prime;

  transition: color 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: #000000;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }

  &::before {
    display: none;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0da";
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.not-found__decor {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;

  display: none;
  background-image: url("../../img/page-not-found.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: $width-laptop) {
    display: block;
  }
}
