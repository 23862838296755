.news {
  padding: 40px 0;

  @media (min-width: $width-tablet-s) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: $width-tablet-s) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: $width-laptop) {
    box-sizing: border-box;
    width: 54.5%;
    padding-top: 75px;
    padding-left: 80px;
    padding-bottom: 100px;
  }
}

.news__list {
  @include list-reset;
  margin-bottom: 40px;

  @media (min-width: $width-laptop) {
    margin-bottom: 60px;
  }
}

.news__title {
  margin: 0;
  margin-bottom: 20px;

  font-family: "Avanti", "Arial", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.025em;
  text-transform: uppercase;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 5px;
  }
}

.news__time {
  display: block;
  margin-bottom: 15px;

  font-family: "Avanti", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 30px;
  }
}

.news__text {
  margin: 0;
  margin-bottom: 20px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.025em;

  &:last-child {
    margin-bottom: 0;
  }
}
