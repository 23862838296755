.gallery {
  width: 100%;
}

.gallery__list {
  @include list-reset;

  margin: 0 -15px;

  @media (min-width: $width-tablet-m) {
    display: flex;
    flex-wrap: wrap;
  }
}

.gallery__item {
  margin-bottom: 30px;
  padding: 0 15px;
}

.gallery__image {
  margin: 0 auto;
  height: 250px;
}
