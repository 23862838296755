.page-about__wrapper {
  @include container;
  width: 100%;
}

.page-about__service {
  margin-bottom: 25px;
}

.page-about__search {
  margin-bottom: 50px;
  max-width: 550px;
}

.page-about__leadership {
  margin-bottom: 50px;
}

.page-about__documents {
  margin-bottom: 50px;

  //@media (min-width: $width-laptop) {
  //  flex-shrink: 0;
  //  margin-bottom: 0;
  //  margin-right: 50px;
  //  width: 350px;
  //}
}

.page-about__picture {
  float: left;
  margin-right: 20px;
}

.page-about__text {
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-m) {
    font-size: 16px;
  }

  p {
    @include clearfix;

    margin: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.page-about__description {
  margin-bottom: 50px;
}

.page-about__title {
  margin: 0;
  margin-bottom: 20px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.page-about__services {
  margin-bottom: 50px;
}

.page-about__contacts {
  margin-bottom: 50px;
}
