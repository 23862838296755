.page-library__wrapper {
  @include container;
}

.page-library__search {
  margin-bottom: 30px;
  max-width: 900px;

  @media (min-width: $width-laptop) {
    margin-bottom: 50px;
  }
}

.page-library__content {
  @include table;

  margin-bottom: 30px;
  margin-right: -15px;
  margin-left: -15px;
  padding-bottom: 10px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 40px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 30px;
    padding-bottom: 0;
  }

  @media (min-width: $width-laptop) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
    overflow: auto;
  }
}

.page-library__table {
  min-width: 100%;
}

.page-library__pagination {
  margin-bottom: 30px;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 40px;
  }

  @media (min-width: $width-tablet-m) {
    margin-bottom: 30px;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 40px;
  }
}
