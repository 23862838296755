.articles {
  @include list-reset;

  @media (min-width: $width-laptop) {
    display: flex;
    flex-wrap: wrap;
  }
}

.articles__item {
  margin-bottom: 15px;

  @media (min-width: $width-laptop) {
    margin-right: 15px;
    width: calc((100% - 15px) / 2);
  }

  &:nth-child(2n) {
    @media (min-width: $width-laptop) {
      margin-right: 0;
    }
  }
}

.articles__link {
  @include link-reset;

  position: relative;
  z-index: 4;

  display: block;
  padding: 50px 30px;
  box-sizing: border-box;
  height: 100%;

  border: 1px solid #000000;

  transition: color 0.2s;

  @media (min-width: $width-tablet-m) {
    padding-left: 60px;
    padding-right: 60px;
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
    border-color: currentColor;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }

  &--imaged {
    overflow: hidden;
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;

      background-color: rgba(0, 0, 0, 0.4);

      transition: transform 0.2s;
    }

    &:hover,
    &:focus,
    &:active {
      background-image: none !important;

      &::before {
        transform: translate(-100%);
      }
    }
  }
}

.articles__title {
  margin: 0;
  margin-bottom: 15px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.articles__text {
  margin: 0;
  margin-bottom: 10px;
  height: 60px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.025em;

  &:last-child {
    margin-bottom: 0;
  }
}
