.table {
  border-collapse: collapse;
}

.table__row {
  &:nth-child(2n) {
    background-color: $color-bg-stripe;
  }
}

.table__cell {
  padding: 10px 15px;
  border-right: 1px solid transparentize($color-prime, 0.8);

  &--head {
    font-family: "Proxima Nova", "Arial", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.025em;
    text-align: left;
  }

  &--body {
    @media (min-width: $width-tablet-s) {
      padding-top: 25px;
      padding-bottom: 25px;
    }

    @media (min-width: $width-tablet-m) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &:last-child {
    border-right: none;
  }
}

.table__link {
  @include link-reset;

  display: flex;
  align-items: center;
  min-width: 230px;
  transition: color 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }

  &--doc {
    display: none;
    max-width: 200px;
    min-width: 100px;

    font-size: 40px;
    text-align: center;

    &:first-child {
      display: block;
    }
  }

  &--simple {
    display: block;
    min-width: 0;
    text-align: center;
  }
}

.table__title,
.table__cell--body {
  margin: 0;
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 1.4;

  @media (min-width: $width-tablet-m) {
    font-size: 13px;
  }
}

.table__image {
  margin: 0;
  flex-shrink: 0;
  margin-right: 10px;
  width: 60px;

  @media (min-width: $width-tablet-m) {
    margin-right: 20px;
    width: 80px;
  }
}

.table__year,
.table__count,
.table__number {
  display: block;
  min-width: 100px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;

  @media (min-width: $width-tablet-m) {
    font-size: 23px;
  }

  &--wrapped {
    min-width: 0;
    width: 75px;
    word-wrap: break-word;
  }
}

.table__count,
.table__number {
  min-width: 0;
}

.table__author {
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.45;
  //white-space: nowrap;

  @media (min-width: $width-tablet-m) {
    font-size: 13px;
  }
}

.table__favorites {
  margin-bottom: 10px;
}

.table__number {
  text-align: right;

  a {
    @include link-reset;

    &:hover,
    &:focus,
    &:active {
      color: $color-prime;
      outline: none;
    }

    &:active {
      opacity: 0.7;
    }
  }
}

.table__price {
  white-space: nowrap;
}
