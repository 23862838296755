.guest {
  padding: 25px 0;
  background-color: #f3f5f7;

  @media (min-width: $width-tablet-s) {
    padding-bottom: 30px;
  }

  @media (min-width: $width-tablet-m) {
    padding-bottom: 40px;
  }
}

.guest__wrapper {
  @include container;
}

.guest__input {
  @include input;

  margin-bottom: 20px;

  @media (min-width: $width-tablet-m) {
    min-height: calc((100% - 20px) / 2);
  }

  &:last-child {
    @media (min-width: $width-tablet-m) {
      margin-bottom: 0;
    }
  }
}

.guest__message {
  @include input;

  margin-bottom: 30px;
  min-height: 100px;
  resize: none;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 0;
    border-right: 1px solid #000000;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (min-width: $width-tablet-m) {
    min-width: 310px;
  }
}

.guest__submit {
  @include label-submit;
  padding-right: 25px;
}

.guest__field {
  margin: 0;

  @media (min-width: $width-tablet-m) {
    margin-right: 35px;
  }

  &:last-child {
    @media (min-width: $width-tablet-m) {
      margin-right: 0;
    }
  }

  &--double {
    @media (min-width: $width-tablet-s) {
      display: flex;
      align-items: flex-end;
    }
  }
}

.guest__form {
  @media (min-width: $width-tablet-m) {
    display: flex;
  }
}
