img {
  display: block;
  max-width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Proxima Nova", "Arial", sans-serif;
  line-height: 1.6;
  background-color: $color-body;
}

main {
  flex-grow: 1;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button {
  appearance: none;
}

input[type="number"],
input[type="date"] {
  appearance: textfield;
}

a[href]:not([class]) {
  color: $color-prime;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:active {
    opacity: 0.7;
  }
}

.nicescroll-cursors {
  left: 12px;
}

.nicescroll-rails {
  width: 5px !important;
  border-radius: 3px;

  &-hr {
    width: 100% !important;
    height: 5px !important;

    .nicescroll-cursors {
      top: calc(5px / 2) !important;
      transform: translateY(-50%);
    }
  }
}

.wrapper {
  @include container;
  width: 100%;
}

input[type="checkbox"] {
  display: none;

  &:checked {
    & + label {
      &::after {
        content: "\2713";
        position: absolute;
        left: 4px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  & + label {
    position: relative;
    padding-left: 30px;
    font-family: "Proxima Nova", "Arial", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.005em;

    &::before {
      content: "";
      position: absolute;
      left: 0;

      box-sizing: border-box;
      width: 20px;
      height: 20px;
      border: 1px solid #000000;
      border-radius: 5px;
    }
  }
}

.hidden {
  display: none !important;
}

.fancybox-caption-custom {
  color: #000000;
  background-color: #ffffff;

  &::before {
    content: none;
  }
}

.quick-view-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  margin: auto;
  max-width: 70%;
}

.quick-view-carousel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 70%;
}

.quick-view-aside {
  position: fixed;
  display: none;
  padding: 10px 15px;
  width: 20%;
  background-color: #ffffff;
}

