.legal {
  margin-bottom: 30px;

  @media (min-width: $width-laptop) {
    margin-bottom: 70px;
  }
}

.legal__title {
  margin: 0;
  margin-bottom: 20px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.legal__content {
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-m) {
    font-size: 16px;
  }

  p {
    margin: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
