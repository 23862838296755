.modal {
  position: fixed;
  top: 20%;
  z-index: 50;

  display: none;
  padding: 40px 0;
  width: 100%;

  background-color: #ffffff;
  box-shadow: 0 0 800px 150px #000000;

  will-change: transform;

  &--login {
    .modal__submit {
      margin-bottom: 10px;
    }

    .modal__link {
      display: block;
      margin-top: 32px;
    }
  }

  &--reg {
    .modal__form {
      max-width: 390px;

      @media (max-width: $width-tablet-s - 1) {
        display: block;
      }
    }

    .modal__submit {
      @media (max-width: $width-tablet-s - 1) {
        border-radius: 20px;
      }
    }

    .modal__column {
      &:last-child {
        &::before {
          @media (max-width: $width-tablet-s - 1) {
            content: none;
          }
        }
      }
    }
  }

  &--recover {
    .modal__form {
      flex-wrap: wrap;
      max-width: none;

      &:first-child {
        margin-bottom: 20px;
      }
    }

    .modal__field {
      &:last-child {
        margin-bottom: 0;
      }

      &--w100 {
        .modal__input {
          max-width: 190px;
          border-radius: 5px;
        }
      }
    }
  }

  &--message {
    .modal__form {
      max-width: none;

      @media (max-width: $width-tablet-s - 1) {
        display: block;
      }
    }

    .modal__submit {
      @media (max-width: $width-tablet-s - 1) {
        border-radius: 20px
      }
    }

    .modal__input {
      border-radius: 5px;
    }

    .modal__column {
      &:first-child {
        @media (min-width: $width-tablet-m) {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 105px;
        }

        .modal__field {
          &:nth-child(2) {
            @media (min-width: $width-tablet-m) {
              margin-bottom: 0;
            }
          }

          &:last-child {
            @media (min-width: $width-tablet-m) {
              margin-left: 30px;
              height: 100%;
            }

            .modal__input {
              height: 100px;

              @media (min-width: $width-tablet-m) {
                height: 100%;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }
        }
      }

      &:last-child {
        width: auto;

        &::before {
          @media (max-width: $width-tablet-s - 1) {
            content: none;
          }
        }
      }
    }

    .modal__field {
      &:last-child {
        @media (min-width: $width-tablet-s) {
          margin-bottom: 0;
        }
      }
    }
  }

  &--buy {
    .modal__wrapper {
      max-width: 750px;
    }
  }

  &--change {
    .modal__field {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.is-open {
    display: block;
  }

  &[aria-hidden="false"] {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden="true"] {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}

.modal__wrapper {
  @include container--modal;
  position: relative;
}

.modal__title {
  margin: 0;
  margin-bottom: 20px;
  max-width: calc(100% - 50px);

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.modal__form {
  display: flex;
  max-width: 340px;
}

.modal__column {
  &:first-child {
    flex-grow: 1;
    width: 100%;
  }

  &:last-child {
    position: relative;

    width: 130px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2px;
      background-color: #000000;
    }
  }
}

.modal__input {
  @include input;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &--single {
    border-radius: 5px;
  }
}

.modal__field {
  margin: 0;
  margin-bottom: 20px;

  &--double {
    display: flex;
  }

  &--w100 {
    width: 100%;
  }
}

.modal__link {
  @include link-reset;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.2;
  color: $color-prime;
  letter-spacing: 0.005em;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }
}

.modal__submit {
  @include label-submit;
  padding: 8px 25px;
}

.modal__reg {
  @include label-submit--trasparent;

  display: inline-block;
  padding: 8px 25px;
}

.modal__close {
  @include btn;

  position: absolute;
  top: 0;
  right: 15px;

  &:hover,
  &:focus,
  &:active {
    color: $color-prime;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }

  .icon {
    font-size: 25px;
  }
}

.modal__columns {
  display: block;

  @media (min-width: $width-tablet-m) {
    display: flex;
  }

  > *:first-child {
    @media (min-width: $width-tablet-m) {
      margin-right: 50px;
    }
  }
}

.modal__text {
  margin: 0;
  margin-bottom: 20px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.005em;
}

.modal__list {
  @include list-reset;

  @media (min-width: $width-tablet-s) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/**************************\
  Demo Animation Style
\**************************/

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}
