.event {
  padding: 30px 15px;

  @media (min-width: $width-laptop) {
    padding: 35px 80px;
  }
}

.event__link {
  @include link-reset;
  display: block;
  outline: none;
}

.event__time {
  font-family: "Avanti", "Arial", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.025em;
  color: #ffffff;
}

.event__title {
  margin: 0;
  margin-bottom: 15px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.025em;
  color: #ffffff;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 25px;
  }
}

.event__text {
  margin: 0;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.075em;
  color: #ffffff;
}
