.subscription {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 15px 20px;
  max-width: 240px;
  border: 1px solid $color-prime;

  @media (min-width: $width-tablet-s) {
    margin-bottom: 0;
    margin-right: 15px;
    padding: 15px;
    width: 140px;
  }

  @media (min-width: $width-tablet-m) {
    margin-right: 60px;
    width: 200px;
  }

  @media (min-width: $width-laptop) {
    margin-right: 20px;
    width: 230px;
  }

  &:last-child {
    margin-bottom: 0;

    @media (min-width: $width-tablet-s) {
      margin-right: 0;
    }
  }
}

.subscription__title {
  margin: 0;
  margin-bottom: 10px;
  width: 100%;

  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  text-transform: lowercase;
  letter-spacing: 0.025em;

  b {
    text-transform: uppercase;
    white-space: nowrap;
  }
}

.subscription__price {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-s) {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 0;
    width: auto;
  }
}

.subscription__btn {
  @include btn--prime;
}
