.promo {
  padding: 50px 0;

  @media (min-width: $width-laptop) {
    position: relative;
    padding: 60px;
    max-width: 420px;
  }

  &::after {
    @media (min-width: $width-laptop) {
      content: "";
      position: absolute;
      right: -50px;
      top: 30px;
      z-index: -1;

      width: 450px;
      height: 75%;

      background-color: $color-body;
      border-radius: 50%;
    }
  }
}

.promo__list {
  @include list-reset;

  padding-bottom: 30px;

  @media (min-width: $width-laptop) {
    padding-bottom: 30px;
  }
}

.promo__title {
  margin: 0;
  margin-bottom: 30px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.35;
  letter-spacing: 0.025em;
  text-transform: uppercase;

  @media (min-width: $width-laptop) {
    margin-bottom: 15px;
  }
}

.promo__text {
  margin: 0;
  margin-bottom: 20px;

  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.025em;

  &:last-child {
    margin-bottom: 0;
  }
}

.promo__link {
  @include capsule-btn--light;

  display: inline-block;
  padding: 10px 15px;
  height: auto;
}
