.toggle {
  @include blue-circle-btn;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.toggle__icon {
  &--close {
    display: none;
  }
}
