.map__wrapper {
  @media (min-width: $width-laptop) {
    @include container;
    display: flex;
  }
}

.map__container {
  position: relative;
  left: -20px;
  flex-grow: 1;
  background-color: lightyellow;

  @media (max-width: $width-laptop) {
    left: 0;
    height: 500px;
  }
}

.map__list {
  @include list-reset;

  @media (max-width: $width-laptop) {
    display: flex;
  }
}

.map__content {
  &--desktop {
    @media (max-width: $width-laptop) {
      display: none;
    }
  }

  &--mobile {
    @media (min-width: $width-laptop) {
      display: none;
    }
  }

  .map__list {
    @media (max-width: $width-laptop) {
      display: flex;
    }
  }
}

.map__city {
  @include link-reset;

  display: block;
  margin: 0;
  padding: 20px 40px;

  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  // white-space: nowrap;

  transition: color 0.2s;

  @media (max-width: $width-laptop) {
    padding: 35px;
  }

  &:hover,
  &:focus,
  &:active,
  &--active {
    color: $color-prime;
    outline: none;
  }
}

.map__addresses {
  position: relative;
  z-index: 1;

  flex-shrink: 0;
  width: 470px;
  height: 750px;

  @media (max-width: $width-laptop) {
    width: auto;
    height: auto;
  }
}
