.history {
  position: relative;

  margin-left: -15px;
  margin-right: -15px;
  padding: 50px 15px;

  color: #ffffff;

  border-color: $color-prime;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: $width-tablet-m) {
    padding: 70px 90px;
  }

  @media (min-width: $width-laptop) {
    margin-left: 0;
    margin-right: 0;
    padding: 70px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  & * {
    position: relative;
    z-index: 5;
  }
}

.history__title {
  font-family: "AvantGardeCTT", "Arial", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
}

.history__content {
  font-family: "Proxima Nova", "Arial", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.025em;

  @media (min-width: $width-tablet-m) {
    font-size: 14px;
  }

  p {
    margin: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
