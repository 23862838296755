.main-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  display: none;
  max-width: 540px;

  background-color: $color-body;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.1);

  // &::before {
  //   @media (min-width: $width-laptop) {
  //     content: "";
  //     position: absolute;
  //     top: -100vh;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;
  //     z-index: -1;
  //
  //     background-color: $color-body;
  //   }
  // }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    z-index: -1;

    height: 1px;

    background-color: $color-prime;
  }

  a {
    @include link-reset;
  }
}

.main-nav__wrapper {
  display: flex;
  padding-top: 120px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $width-laptop) {
    position: relative;
    padding-bottom: 63px;
  }

  // &::before {
  //   @media (min-width: $width-laptop) {
  //     content: "";
  //     position: absolute;
  //     top: 30px;
  //     right: 25px;
  //     width: 5px;
  //     height: 100px;
  //
  //     background-color: $color-prime;
  //   }
  // }
}

.main-nav__list {
  @include list-reset;

  &--lvl1 {
    width: 50%;
    line-height: 1.15;

    a {
      display: inline;
      padding-right: 5px;

      font-family: "Proxima Nova", "Arial", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.3;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      border: none;
      border-bottom: 1px solid transparent;

      transition: color 0.2s;

      &:hover,
      &:focus,
      &:active {
        color: $color-prime;
        outline: none;
        border-color: currentColor;
      }

      &:active {
        opacity: 0.7;
      }

      &.tab {
        border-style: dashed;
      }
    }

    .active {
      color: $color-prime;
    }

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.main-nav__content {
  width: 50%;
  max-height: 400px;
  overflow-y: scroll;

  a {
    display: block;
    padding-left: 5px;

    font-family: "Proxima Nova", "Arial", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    letter-spacing: 0.05em;

    transition: color 0.2s;

    &:hover,
    &:focus,
    &:active {
      color: $color-prime;
      outline: none;
    }

    &:active {
      opacity: 0.7;
    }
  }

  ul {
    display: none;
  }

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
