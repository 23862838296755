.page-header {
  padding-bottom: 15px;

  @media (min-width: $width-tablet-s) {
    padding-top: 15px;
  }

  &--index {
    .page-header__wrapper {
      @media (min-width: $width-laptop) {
        position: relative;
      }
    }

    .page-header__links {
      @media (min-width: $width-laptop) {
        color: #ffffff;
      }
    }

    .page-header__link {
      color: #ffffff;
    }

    .page-header__user-area {
      color: #ffffff;
    }

    .page-header__logo {
      @media (min-width: $width-laptop) {
        max-width: none;
        width: 690px;
      }
    }
  }
}

.page-header__wrapper {
  @include container;
}

.page-header__top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.page-header__logo {
  display: block;
  max-width: 190px;
  color: $color-prime;
  transition: color 0.2s;

  @media (min-width: $width-tablet-s) {
    max-width: 250px;
  }

  @media (min-width: $width-laptop) {
    position: relative;
    z-index: 10;
    max-width: 320px;
  }

  &:hover,
  &:focus,
  &:active {
    color: #000000;
    outline: none;
  }

  &:active {
    opacity: 0.7;
  }

  &--mobile {
    display: block;

    @media (min-width: $width-laptop) {
      display: none;
    }
  }

  &--desktop {
    display: none;
    margin-left: 10px;

    @media (min-width: $width-laptop) {
      display: block;
    }
  }
}

.page-header__social {
  display: none;
  margin: 0;

  @media (min-width: $width-laptop) {
    display: block;
  }
}

.page-header__version {
  margin: 0;

  @media (min-width: $width-tablet-s) {
    justify-content: flex-end;
  }

  @media (min-width: $width-laptop) {
    margin-bottom: 10px;
  }
}

.page-header__bottom {
  position: relative;
  z-index: 20;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    left: calc((100vw - 100%) / -2);
    top: 50%;
    z-index: -1;

    width: 100vw;
    height: 1px;

    background-color: $color-prime;

    @media (min-width: $width-laptop) {
      left: calc((100vw - 100% + 17px) / -2);
    }
  }
}

.page-header__toggle {
  z-index: 10;
  margin-right: 20px;
}

.page-header__shedule {
  @include capsule-btn--light;
  margin-right: 20px;
}

.page-header__favorites {
  @include capsule-btn--dark;
  margin-right: 20px;
}

.page-header__info {
  @include capsule-btn--dark;
}

.page-header__search {
  margin-left: auto;
}

.page-header__playback {
  display: none;
  position: absolute;
  top: -15px;
  right: calc((100vw - 100% - 17px) / -2);
  bottom: -15px;
  left: 430px;
  z-index: -2;

  overflow: hidden;

  background-image: url("../img/bg-header-index.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (min-width: $width-laptop) {
    display: block;
  }
}

.page-header__video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.page-header__area {
  display: none;

  @media (min-width: $width-desktop) {
    display: block;
  }

  @media (min-width: $width-map-icons-to-column) {
    bottom: -30px;
  }
}

.page-header__links {
  text-align: right;
}

.page-header__link {
  @include link-reset;

  font-family: "Avanti", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  text-align: right;
  border-bottom: 1px dashed currentColor;
  color: $color-prime;

  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }

  &:active {
    opacity: 0.7;
  }

  &--user-area {
    display: none;

    @media (min-width: $width-tablet-s) {
      display: inline-block;
    }

    &.page-header__link--mobile {
      box-sizing: border-box;
      margin-bottom: 1px;
      padding: 10px;
      width: 100%;

      color: #ffffff;
      text-align: center;

      border-bottom: none;
      background-color: $color-prime;

      &:active {
        opacity: 0.7;
      }

      &:last-child {
        margin-bottom: 15px;
      }
    }
  }

  &--mobile {
    display: block;

    @media (min-width: $width-tablet-s) {
      display: none;
    }
  }
}

.page-header__user-area {
  position: relative;
  z-index: 100;
}
